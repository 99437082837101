import { NextPage } from "next";

interface Props {
  type: "button" | "submit" | "reset";
  customClassName: string;
  text: string;
  disabled?: boolean;
}

const Button: NextPage<Props> = ({ type, customClassName, text , disabled=false}) => {
  return (
    <button
      type={type}
      className={`${customClassName} hover:opacity-75 text-center rounded-[4px] py-[8px] bg-[#E25822] w-full ${disabled ? "opacity-50 cursor-not-allowed" : ""}`}
      disabled={disabled}
    >
      {text}
    </button>
  );
};

export default Button;

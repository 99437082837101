import InputField from "@/components/Frontend/Inputs/InputField";
import Button from "@/components/Frontend/CTA/Button";
import { NextPage } from "next";
import Link from "next/link";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { useRouter } from "next/router";
import AuthContext from "@/context/AuthContext";

interface Props {}

const Login: NextPage<Props> = ({}) => {
  // const [email, setEmail] = useState("");
  // const [password, setPassword] = useState("");
  // const [showPassword, setShowPassword] = useState(false);

  const router = useRouter();
  let { loginUser, user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [showPassword,] = useState(false);
  const [, setShowLoginError] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setError("");
  };

  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    setError("");
  };

  useEffect(() => {
    if (user) {
      router.push("/");
    } else {
      router.push("/account/login");
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    setShowLoginError(false);
    await loginUser(e);

    try {
      const { error } = await loginUser(e);
      if (error) {
        setError(error);
        setShowLoginError(true);
      } else {
        setLoading(true);
        setShowLoginError(false);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="flex justify-center items-center mt-5">
        <div className="w-[334px] sm:w-[375px]">
          <div className="flex flex-col justify-center items-center gap-[15px]">
            <h4 className="text-[20px] font-[600]">Login</h4>
            <h4 className="text-[12px] font-[400] text-[#777777]">
              Best place to buy and sell digital products
            </h4>
          </div>
          <div className="flex mt-[38px] flex-col min-h-[334px] border p-[38px_34px_38px_34px]">
            <form
              className="flex flex-col gap-7"
              action="#"
              method="POST"
              onSubmit={handleLogin}
            >
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700 remhub-body-nunito-m"
                >
                  Email Address
                </label>
                <InputField
                  id="email"
                  name="email"
                  type={"email"}
                  autoComplete="current-email"
                  isRequired={true}
                  value={email}
                  onChange={handleEmailChange}
                />
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700 remhub-body-nunito-m"
                >
                  Password
                </label>
                <InputField
                  id="password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  autoComplete="current-password"
                  isRequired={true}
                  value={password}
                  onChange={handlePasswordChange}
                />
                {error && (
                  <p className="text-[red] hbm-body-nunito-s py-1">{error}</p>
                )}
              </div>
              <Button
                type={"submit"}
                customClassName={"text-white text-xs"}
                text={loading ? "Logging In..." : "Login"}
              />
            </form>
            <h4 className="text-sm font-extraligh mt-6 text-center">
              Already have an Account?{" "}
              <Link className="text-purple-600" href={"/account/register"}>
                Register
              </Link>
            </h4>
            <h4 className="text-sm font-extraligh mt-6 text-center">
              Forgot Password?{" "}
              <Link
                className="text-purple-600"
                href={"/account/password_reset/"}
              >
                Change Password
              </Link>
            </h4>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;

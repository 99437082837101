import React from "react";

interface InputFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  id: string;
  name: string;
  type: string;
  maxLength?: number;
  minLength?: number;
  autoComplete?: string;
  isRequired?: boolean;
  value: string | number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  readOnly?: boolean;
}

const InputField: React.FC<InputFieldProps> = ({
  id,
  type,
  name,
  autoComplete,
  isRequired,
  value,
  onChange,
  maxLength,
  minLength,
  readOnly,
}) => {
  return (
    <div className="relative">
      <input
        type={type}
        id={id}
        name={name}
        autoComplete={autoComplete || ""}
        required={isRequired}
        value={value}
        minLength={minLength}
        maxLength={maxLength || 255}
        onChange={onChange}
        readOnly={readOnly}
        className={`w-full border 
          border-gray-300 
          rounded-[4px] px-3 py-2 
          focus:outline-none 
          ${readOnly 
            ? 'bg-gray-50 text-gray-500 border-gray-200' 
            : 'focus:border-[#4B4DED]'
          }`}
      />
      {readOnly && (
        <div className="absolute right-2 top-1/2 transform -translate-y-1/2">
          <svg 
            className="w-4 h-4 text-gray-400" 
            fill="none" 
            stroke="currentColor" 
            viewBox="0 0 24 24"
          >
            <path 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              strokeWidth={2} 
              d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" 
            />
          </svg>
        </div>
      )}
    </div>
  );
};

export default InputField;
